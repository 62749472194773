import { navigate } from 'gatsby';
import React, {useEffect, useState} from 'react';
import Layout from '../components/layout';

const ThankYou = () => {
  const [redirectUrl, setRedirectUrl] = useState('https://www.pensiontimes.co.uk');

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.returnUrl && params.returnUrl !== '') {
      setRedirectUrl(params.returnUrl)
    }

    const timer = setTimeout(() => {
      navigate(params?.returnUrl || redirectUrl);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout>
      <main className="wrapper main-data-container" data-datocms-noindex>
        <h1>Thank You!</h1>
        <p>Thank you for entering our competition. <br/> You will be redirected back to the article you were reading in 5 seconds. If you're not redirected, please <a href={redirectUrl} className="underline">click here</a>.</p>
      </main>
    </Layout>
  );
};

export default ThankYou;
